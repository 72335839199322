import './App.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'

import ShrimpPepe from './assets/SHRIMPEPEWORLD.jpg'

import Colors from './Colors'

function App() {
  return (
    <Container className="App">
      <Row
        style={{
          fontFamily: 'VT323',
          fontSize: '40px',
          color: Colors.pink,
          margin: '32px'
        }}
      >
        shrimp
      </Row>
      <Row>
        <Image
          roundedCircle
          src={ShrimpPepe}
          style={{
            height: '20vh',
            marginBottom: '24px'
          }}
        />
      </Row>
      <Row>
        Stage Name: shrimp
      </Row>
      <Row>
        Birth Name: ShrimpPepe
      </Row>
      <Row>
        Nicknames: shrimp, shrimp pepe, simp pepe
      </Row>
      <Row>
        Position: Internet Magician
      </Row>
      <Row>
        Zodiac Sign: Cancer
      </Row>
      <Row>
        Birthplace: 127.0.0.1
      </Row>
      <Row>
        Height: 182 cm (6 ft)
      </Row>
      <Row>
        Weight: 44 kg (97 lbs)
      </Row>
      <Row>
        Blood Type: O-
      </Row>
      <Row>
        <span>Github: </span>
        <span>
          <a href="https://github.com/shrimppepe">
            shrimppepe
          </a>
        </span>
      </Row>
      <Row>
        <span>Twitter: </span>
        <span>
          <a href="https://twitter.com/ShrimpPepe">
            @ShrimpPepe
          </a>
        </span>
      </Row>
      <Row>
        Fun side projects:
      </Row>
      <Row>
        <span>A Pokemon ENS social club: </span>
        <span>
          <a href="https://twitter.com/trainerdao">
            @TrainerDAO
          </a>
        </span>
      </Row>
      <Row>
        <span>A Martin Shkreli simp DAO: </span>
        <span>
          <a href="https://twitter.com/pharmabrodao">
            @pharmabrodao
          </a>
        </span>
      </Row>
    </Container>
  );
}

export default App;
